// Component imports.


// Style imports.
import styles from "./SearchResult.module.scss";

// Media imports.


// TODO: Get started on this page.
/**
 * Renders the Search Result page.
 * @returns {JSX.Element} JSX Component.
 */
const SearchResult = () => {
  // TODO: Include layout for search results.

  return (
    <>
      <div className="container">

      </div>
    </>
  );
};

export default SearchResult;
