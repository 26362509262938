
const bgImgSrc = "/media/background-hd.png"; // https://images5.alphacoders.com/115/1156667.png
const bgOverlayAlpha = 0.6;
const bgBlur = 2;


export {
  bgImgSrc,
  bgOverlayAlpha,
  bgBlur,
};
